import React, { useEffect, useContext } from "react";
import {Button, MainTitle} from "../components/Core";
import PageWrapper from "../components/PageWrapper";
import styled from "styled-components";
import { Title, Box, Text } from "../components/Core";
import { device } from "../utils";
import { Col, Container, Row } from "react-bootstrap";
import CheckAvailability from "../sections/index/CheckAvailability";
import Seo from "../components/Seo/Seo";
import scrollTo from 'gatsby-plugin-smoothscroll';
import {Link} from "gatsby";
import sbvsLogo from "./../assets/image/jpeg/sbvs-logo.jpg"
import GlobalContext from './../context/GlobalContext';
import { graphql, useStaticQuery } from "gatsby";
import { getProperty } from "../utils/helperFn";
import ReactMarkdown from 'react-markdown'
const gfm = require('remark-gfm')

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  padding-top: 100px;
`;

const BoxInner = styled(Box)`
  min-height: 100vh;
`;

const BigTitle = styled(MainTitle)`{
  font-size: 2.8em;
}`

const NormalTitle = styled(Title)`{
  font-size: 2.3em;
}`

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({ theme }) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.light};
  width: 100%;
`;


const R100 = () => {
    const gContext = useContext(GlobalContext);
    useEffect(() => {
        gContext.goResetRegistrationData()
    }, [])

    const data = useStaticQuery(graphql`
        query strapiRscotlandQuery {
            strapiRscotland {
                title
                subtitle_text
                subtitle_1
                subtitle_1_text
                subtitle_2
                subtitle_2_text
                subtitle_3
                subtitle_3_text
            }
            strapiPageHeadings(slug: {eq: "scottish-voucher-scheme"}) {
                h1
                h21
                h22
                h23
            }
        }`
    );

    const pageData = getProperty(data, 'strapiRscotland');
    const headingsData = getProperty(data, 'strapiPageHeadings');

    return (
        <div>
            <Seo page="scotland-r-100" />
            <PageWrapper footerDark>
                <BoxStyled bg="#f7f8fa" style={{ paddingBottom: '80px' }} className="voucher-page-wrapper">
                    <BoxInner className="d-flex align-items-center">
                        <Container>
                            <Box mx="auto">
                                <Row className="justify-content-center">
                                    <Col lg="12">
                                        <FormStyled>
                                            <Row>
                                                <Col sm={12} lg={11} className="mx-auto">
                                                    <div className="mb-3 pb-2 text-left">
                                                        <BigTitle className="m-3 pt-3 text-center">
                                                            {headingsData.h1}
                                                        </BigTitle>
                                                        <Text variant="card" className="pt-4 voucher-text">
                                                            <ReactMarkdown plugins={[gfm]} source={pageData.subtitle_text}/>
                                                            <Row className="text-center">
                                                                <Col sm={12} lg={6} className="mx-auto">
                                                                    <Button
                                                                        style={{
                                                                            background: 'linear-gradient(90deg, rgba(252,58,76,1) 0%, rgba(206,3,144,1) 100%)',
                                                                            border: 'none',
                                                                            width: 'auto'
                                                                        }}
                                                                        className="btn-red hvr-bounce-to-left mt-5 mb-5"
                                                                        onClick={() => scrollTo('#check-availability')}
                                                                    >
                                                                        Register now
                                                                    </Button>
                                                                </Col>
                                                            </Row>
                                                            <NormalTitle className="m-3 pt-3 text-center">
                                                                {headingsData.h21}
                                                            </NormalTitle>
                                                            <ReactMarkdown plugins={[gfm]} source={pageData.subtitle_1_text}/>
                                                            <NormalTitle className="m-3 pt-3 text-center">
                                                                {headingsData.h22}
                                                            </NormalTitle>
                                                            <ReactMarkdown plugins={[gfm]} source={pageData.subtitle_2_text}/>
                                                            <NormalTitle className="m-3 pt-3 text-center">
                                                                {headingsData.h23}
                                                            </NormalTitle>
                                                            <ReactMarkdown plugins={[gfm]} source={pageData.subtitle_3_text}/>
                                                        </Text>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <img src={sbvsLogo} alt="GBVS" className="mx-auto d-block" width="250px"/>
                                        </FormStyled>
                                    </Col>
                                </Row>
                            </Box>
                        </Container>
                    </BoxInner>
                </BoxStyled>

                <div className="voucher-page-wrapper" id="check-availability">
                    <div className="voucher-page-container">
                        <CheckAvailability focusInput={false}/>
                    </div>
                </div>
            </PageWrapper>
        </div>
    )
}

export default R100
